import { observer, Provider } from 'mobx-react';import { Route, Router, Switch } from 'react-router-dom';
import DefaultLayout from './layouts/defaultLayout/defaultLayout';
import { createBrowserHistory } from "history";import CurrentUserStore from './stores/CurrentUserStore';
import SourceStore from './stores/SourceStore';
import WebsiteStore from './stores/WebsiteStore';
import PageStore from './stores/PageStore';
import LoginPage from './pages/account/loginPage';
import ErrorBoundary from './utils/ErrorBoundary';
import RegisterPage from './pages/account/registerPage';
import ForgottenPasswordPage from './pages/account/forgottenPasswordPage';
import BaseComponent from './components/baseComponent';import CreateWebsitePage from './pages/websites/createWebsitePage';
import LoadingScreen from './components/screens/loadingScreen';
import ManageWebsitePage from './pages/websites/manageWebsitePage';
import { isNil } from 'lodash';
import Pusher from 'pusher-js';
import StoreUtil from './stores/storeUtil';
import SourcesListPage from './pages/websites/sources/sourcesListPage';
import SourceSettingsPage from './pages/websites/sources/sourceSettingsPage';
import PagesListPage from './pages/websites/pages/pagesListPage';
import CategoryStore from './stores/CategoryStore';
import CategoriesListPage from './pages/websites/categories/categoriesListPage';
import PageEditPage from './pages/websites/pages/pageEditPage';
import ThemeStore from './stores/ThemeStore';
import ThemesListPage from './pages/themes/themesListPage';
import VariableStore from './stores/VariableStore';
import VariablesListPage from './pages/tools/variables/variablesListPage';
import SectionStore from './stores/SectionStore';
import SectionsListPage from './pages/websites/sections/sectionsListPage';
import React from 'react';
import WebsiteBasicSettingsPage from './pages/websites/settings/websiteBasicSettingsPage';
import MenuStore from './stores/MenuStore';
import MenuItemStore from './stores/MenuItemStore';
import MenusManagePage from './pages/websites/menus/menusManagePage';
import { ToastContainer } from 'react-toastify';
import ImageStore from './stores/ImageStore';
import GaAccountStore from './stores/GaAccountStore';
import SettingsPage from './pages/account/settingsPage';
import WebsiteGroupStore from './stores/WebsiteGroupStore';
import WebsiteListPage from './pages/websites/websitesListPage';
import WebsiteVariablesListPage from './pages/websites/variables/websiteVariablesListPage';
import StorageFileStore from './stores/StorageFileStore';
import StorageFilesListPage from './pages/storage/storageFilesListPage';
import WebsiteChangeDomainPage from './pages/websites/settings/websiteChangeDomainPage';
import { ImageManagerPage } from './pages/websites/media/imageManagerPage';
import WebsiteEditCustomCss from './pages/websites/files/websiteEditCustomCssPage';
export const history = createBrowserHistory();interface IState {    loading: boolean,
}interface IProps {
}@observerclass App extends BaseComponent<IProps, IState> {    public static instance: App;    public currentUserStore: CurrentUserStore = new CurrentUserStore();    public sourceStore: SourceStore = new SourceStore();    public websiteStore: WebsiteStore = new WebsiteStore();    public pageStore: PageStore = new PageStore();    public categoryStore: CategoryStore = new CategoryStore();    public themeStore: ThemeStore = new ThemeStore();    public variableStore: VariableStore = new VariableStore();    public sectionStore: SectionStore = new SectionStore();    public menuStore: MenuStore = new MenuStore();    public menuItemStore: MenuItemStore = new MenuItemStore();    public imageStore: ImageStore = new ImageStore();    public gaAccountStore: GaAccountStore = new GaAccountStore();    public websiteGroupStore: WebsiteGroupStore = new WebsiteGroupStore();    public storageFileStore: StorageFileStore = new StorageFileStore();    constructor(props: IProps) {        super(props);        this.state = {            loading: true
        }        App.instance = this;    }    componentDidMount() {        this.usersApiService.getCurrentUser()
            .then(user => {

                if (!isNil(user)) {
                    let pusherKey: string = process.env.NODE_ENV === 'development' ? "5325249fd57f27ea326b" : "e391d27928b68e614566";

                    var pusher = new Pusher(pusherKey, {
                        cluster: 'eu',
                        authTransport: 'ajax',
                        authEndpoint: '/api/users/current/pusher/auth',
                    });

                    Pusher.logToConsole = process.env.NODE_ENV === 'development';

                    var channel = pusher.subscribe('private-user-' + user.id);

                    channel.bind('onStoreChange', (data: any) => {
                        StoreUtil.process(data);
                    });
                }
            })
            .finally(() => {
                this.setState({
                    loading: false
                })
            })
    }    public render() {        if (this.state.loading) {
            return <LoadingScreen />
        }        return (            <Provider                currentUserStore={this.currentUserStore}                sourceStore={this.sourceStore}                websiteStore={this.websiteStore}                pageStore={this.pageStore}                categoryStore={this.categoryStore}                themeStore={this.themeStore}                variableStore={this.variableStore}                sectionStore={this.sectionStore}                menuStore={this.menuStore}                menuItemStore={this.menuItemStore}                imageStore={this.imageStore}                gaAccountStore={this.gaAccountStore}                websiteGroupStore={this.websiteGroupStore}                storageFileStore={this.storageFileStore}            >                <div className="App">
                    <Router history={history}>
                        <ToastContainer />
                        <Switch>                            <Route exact={true} render={(props) => (
                                <ErrorBoundary>
                                    <LoginPage  {...props} />
                                </ErrorBoundary>
                            )} path="/login" />                            {/*   <Route exact={true} render={(props) => (
                                <ErrorBoundary>
                                    <RegisterPage  {...props} />
                                </ErrorBoundary>
                            )} path="/register" /> */}                            <Route exact={true} render={(props) => (
                                <ErrorBoundary>
                                    <ForgottenPasswordPage {...props} />
                                </ErrorBoundary>
                            )} path="/forgotten-password" />                            <Route exact={true} render={(props) => (
                                <DefaultLayout match={props.match as any} >
                                    <ErrorBoundary>
                                        <ThemesListPage />
                                    </ErrorBoundary>
                                </DefaultLayout>
                            )} path="/themes" />                            <Route exact={true} render={(props) => (
                                <DefaultLayout match={props.match as any} >
                                    <ErrorBoundary>
                                        <VariablesListPage />
                                    </ErrorBoundary>
                                </DefaultLayout>
                            )} path="/tools/variables" />                            <Route exact={true} render={(props) => (
                                <DefaultLayout match={props.match as any} >
                                    <ErrorBoundary>
                                        <SettingsPage />
                                    </ErrorBoundary>
                                </DefaultLayout>
                            )} path="/settings" />                            <Route exact={true} render={(props) => (
                                <DefaultLayout match={props.match as any} >
                                    <ErrorBoundary>
                                        <WebsiteListPage />
                                    </ErrorBoundary>
                                </DefaultLayout>
                            )} path="/websites" />                            <Route exact={true} render={(props) => (
                                <DefaultLayout match={props.match as any} >
                                    <ErrorBoundary>
                                        <CreateWebsitePage  {...props} />
                                    </ErrorBoundary>
                                </DefaultLayout>
                            )} path="/websites/create" />                            <Route exact={true} render={(props) => (
                                <DefaultLayout match={props.match as any}>
                                    <ErrorBoundary>
                                        <ManageWebsitePage match={props.match as any} />
                                    </ErrorBoundary>
                                </DefaultLayout>

                            )} path="/websites/:websiteId/manage" />                            <Route exact={true} render={(props) => (
                                <DefaultLayout match={props.match as any} >
                                    <ErrorBoundary>
                                        <SourcesListPage match={props.match as any} />
                                    </ErrorBoundary>
                                </DefaultLayout>
                            )} path="/websites/:websiteId/sources" />                            <Route exact={true} render={(props) => (
                                <DefaultLayout match={props.match as any} >
                                    <ErrorBoundary>
                                        <SourceSettingsPage match={props.match as any} />
                                    </ErrorBoundary>
                                </DefaultLayout>
                            )} path="/websites/:websiteId/sources/:sourceId/settings" />                            <Route exact={true} render={(props) => (
                                <DefaultLayout match={props.match as any} >
                                    <ErrorBoundary>
                                        <PagesListPage match={props.match as any} />
                                    </ErrorBoundary>
                                </DefaultLayout>
                            )} path="/websites/:websiteId/pages" />                            <Route exact={true} render={(props) => (
                                <DefaultLayout match={props.match as any} >
                                    <ErrorBoundary>
                                        <WebsiteEditCustomCss match={props.match as any} />
                                    </ErrorBoundary>
                                </DefaultLayout>
                            )} path="/websites/:websiteId/css" />                                                        <Route exact={true} render={(props) => (
                                <DefaultLayout match={props.match as any} >
                                    <ErrorBoundary>
                                        <PageEditPage match={props.match as any} />
                                    </ErrorBoundary>
                                </DefaultLayout>
                            )} path="/websites/:websiteId/pages/:pageId/edit" />                            <Route exact={true} render={(props) => (
                                <DefaultLayout match={props.match as any} >
                                    <ErrorBoundary>
                                        <CategoriesListPage match={props.match as any} />
                                    </ErrorBoundary>
                                </DefaultLayout>
                            )} path="/websites/:websiteId/categories" />                            <Route exact={true} render={(props) => (
                                <DefaultLayout match={props.match as any} >
                                    <ErrorBoundary>
                                        <SectionsListPage match={props.match as any} />
                                    </ErrorBoundary>
                                </DefaultLayout>
                            )} path="/websites/:websiteId/sections" />                            <Route exact={true} render={(props) => (
                                <DefaultLayout match={props.match as any} >
                                    <ErrorBoundary>
                                        <WebsiteBasicSettingsPage match={props.match as any} />
                                    </ErrorBoundary>
                                </DefaultLayout>
                            )} path="/websites/:websiteId/settings/basic" />                            {/*   <Route exact={true} render={(props) => (
                                <DefaultLayout match={props.match as any} >
                                    <ErrorBoundary>
                                        <WebsiteGaSettingsPage match={props.match as any} />
                                    </ErrorBoundary>
                                </DefaultLayout>
                            )} path="/websites/:websiteId/settings/google-analytics" />*/}                                                        <Route exact={true} render={(props) => (
                                <DefaultLayout match={props.match as any} >
                                    <ErrorBoundary>
                                        <MenusManagePage match={props.match as any} />
                                    </ErrorBoundary>
                                </DefaultLayout>
                            )} path="/websites/:websiteId/menus" />                            <Route exact={true} render={(props) => (
                                <DefaultLayout match={props.match as any} >
                                    <ErrorBoundary>
                                        <WebsiteVariablesListPage match={props.match as any} />
                                    </ErrorBoundary>
                                </DefaultLayout>
                            )} path="/websites/:websiteId/variables" />                            <Route exact={true} render={(props) => (
                                <DefaultLayout match={props.match as any} >
                                    <ErrorBoundary>
                                        <ImageManagerPage match={props.match as any} />
                                    </ErrorBoundary>
                                </DefaultLayout>
                            )} path="/websites/:websiteId/media/images" />                            <Route exact={true} render={(props) => (
                                <DefaultLayout match={props.match as any} >
                                    <ErrorBoundary>
                                        <WebsiteChangeDomainPage match={props.match as any} />
                                    </ErrorBoundary>
                                </DefaultLayout>
                            )} path="/websites/:websiteId/settings/change-domain" />                            <Route exact={true} render={(props) => (
                                <DefaultLayout match={props.match as any} >
                                    <ErrorBoundary>
                                        <StorageFilesListPage />
                                    </ErrorBoundary>
                                </DefaultLayout>
                            )} path="/storage" />                                                       <Route exact={true} render={(props) => (
                                <DefaultLayout match={props.match as any} >
                                    <ErrorBoundary>
                                        <WebsiteListPage />
                                    </ErrorBoundary>
                                </DefaultLayout>
                            )} path="/" />                            </Switch>
                    </Router>
                </div>            </Provider>        );    }}export default App;