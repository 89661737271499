import IUser from "../interfaces/IUser";
import IResponseData from "../interfaces/records/IResponseData";
import BaseApiService from "./baseApiService";

export default class UsersApiService extends BaseApiService {

    constructor() {
        super();
    }

    public getCurrentUser(): Promise<IUser> {
        return this.getRequest(`users/current`, {})
            .then(response => {
                const user: IUser = response.data.records.currentUser;
                return user;
            })
    }

    public register(email: string, password: string): Promise<IResponseData> {
        return this.postRequest(`users/register`, { email, password }, {})
            .then(response => {
                return response.data;
            })
    }

    public login(email: string, password: string): Promise<IResponseData> {
        return this.postRequest('users/login', { email, password }, {})
            .then(response => {
                return response.data;
            })
    }

    public logout(): Promise<IResponseData> {
        return this.postRequest('users/logout', { }, {})
            .then(response => {
                return response.data;
            })
    }
/*
    public getGaAccounts(): Promise<IResponseData> {
        return this.getRequest(`users/current/google-analytics/accounts`, {})
            .then(response => {
                return response.data;
            })
    }

    public setGoogleApiKey(googleApiKey: string): Promise<IResponseData> {
        return this.postRequest('users/current/google-api-key', { googleApiKey }, {})
            .then(response => {
                return response.data;
            })
    }*/
}